.module-video {
    .embed-responsive {
        margin: 20px auto;

        .uc-embedding-container {
            min-height: 200px;
            @media (max-width: $screen-xs-max) {
                width: 100%;
            }
            @media (min-width: $screen-sm) {
                min-width: 750px;
                max-width: 100%;
                min-height: 420px;
            }
        }
    }
}