@media(max-width: $screen-sm-max) {
    .module-empty_space {
        &.hide-mobile {
            display: none;
        }
    }
}

@media(min-width: $screen-md-min) {
    .module-empty_space {
        &.hide-desktop {
            display: none;
        }
    }
}