.module-retailer {
    position: relative;
    background-color: $BODY_BG_COLOR;
    @include clearfix();
    @include make-row();

    .retailer-wrapper {
        @include make-xs-column(12);

        margin-bottom: 50px;

        .map-form-wrapper {
            padding: 30px;
            background-color: $BOX_BG_COLOR_LIGHT;
            margin-bottom: 1px;
            margin-top: 20px;

            input {
                width: calc(100% - 44px);
                @include breakpoint(sm) {
                    width: 50%;
                }
            }
        }

        .map-wrapper {
            height: 400px;
            position: relative;

            iframe.retailer-consent {
                visibility: hidden;
            }

            .uc-embedding-container {
                height: inherit;
                width: 100%;
            }

            .map {
                height: 100%;

                .location-inner {
                    float: left;
                    margin-top: remcalc(40px);
                    padding-right: 50px;
                    @include breakpoint(sm-only) {
                        max-width: 410px;
                    }
                }

                .location-inner {
                    @include breakpoint(xs-only) {
                        padding-right: 0;
                    }
                }

                .phone {
                    color: $MAIN_COLOR;

                    &:hover {
                        text-decoration: none;
                    }
                }

                .location-extras {
                    margin: 20px 0 10px 0;
                    text-align: right;
                    @media (max-width: $screen-sm) {
                        float: none;
                        text-align: left;
                    }
                }

                .distance-holder {
                    float: left;
                    margin-top: remcalc(19px);
                    @media (max-width: $screen-sm) {
                        margin-top: remcalc(40px);
                        display: inline-block;
                        width: 100%;
                        float: none;
                        clear: both;
                    }
                }


                .gm-style-iw {
                    @include breakpoint(xs-only) {
                        max-width: 80%;
                    }
                }
            }

            .uc-embedding-container + .uc-embedding-container {
                display: none;
            }
        }

        .distance-holder {
            .icon-geo_pin {
                position: relative;
                display: inline-block;

                width: 25px;

                font-size: 40px;
                color: $SECONDARY_LIGHT_COLOR;


                .pin_id {
                    position: absolute;
                    top: 0;
                    left: 8px;


                    @include font-DIN-next-bold(12px, 24px);
                    text-align: center;
                }
            }
        }


        .location-extras {
            margin-top: 50px;

            .direction-link {
                position: relative;
                color: $MAIN_COLOR;
                padding-left: 18px;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 1px;
                    @extend .dolle-icon;
                    content: $icon-arrow_right_mini;
                    display: inline-block;
                    width: 14px;
                    font-size: 10px;
                    line-height: 16px;
                    color: $SECONDARY_LIGHT_COLOR;
                }
            }
        }

        .location-select {
            .location {
                clear: both;
                position: relative;
                padding-bottom: 20px;
                border-bottom: 1px solid $MAIN_LIGHT_COLOR;

                .location-inner {
                    columns: 2;
                    column-gap: 30px;
                    clear: both;
                    padding-top: 25px;

                    @include breakpoint(xs-only) {
                        columns: 1;
                    }

                    .phone {
                        color: $MAIN_COLOR;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                .distance-holder {
                    position: absolute;
                    top: 12px;
                    right: 0;

                    @include breakpoint(xs-only) {
                        .distance-text {
                            display: none;
                        }
                    }

                    @include breakpoint(sm) {
                        top: 26px;
                    }
                }

                .name {
                    float: left;

                    max-width: 70%;

                    margin-top: remcalc(40px);

                    font-size: remcalc(30px);

                    @include breakpoint(md) {
                        max-width: none;
                    }
                }

                .address,
                .name {
                    cursor: pointer;
                }
            }
        }

        .geolocate-btn {
            display: block;
            cursor: pointer;
            margin-top: 10px;
            text-align: center;
            @include breakpoint(sm) {
                text-align: left;
                display: inline-block;
                margin-top: 0;
                margin-left: 8px;
            }
        }
    }
}

#dolle-dk {
    .module-retailer .retailer-wrapper .map-wrapper {
        height: 600px;
    }
}