h1, h2, h3 {
  color: $MAIN_COLOR;

  .important,
  .detail {
    color: $SECONDARY_LIGHT_COLOR;
  }
}

h1,
h1.page-title {
  @include primary-headline-config();
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 20px;
}

h2 {
  @include secondary-headline-config(light);
  text-align: center;
  text-transform: uppercase;

  .content-shelving & {
    text-align: left;
  }
}

h3 {
  @include tertiary-headline-config(light);
}

.opc-wrapper .step-title,
h4 {
  color: $MAIN_COLOR;
  @include font-DIN-next-regular(remcalc(20px));
  text-transform: uppercase;
}

h5 {
  color: $MAIN_COLOR;
  @include font-DIN-next-bold(remcalc(18px));
  text-transform: uppercase;
}

h6 {
  @include font-DIN-next-light(remcalc(20px), remcalc(30px));
}

p {
  @include font-DIN-next-light(remcalc($FONTSIZE_BASE_SM_UP), remcalc(24px));

  &.bold, b, strong {
    @include font-DIN-next-regular(remcalc($FONTSIZE_BASE_SM_UP), remcalc(24px));
  }

  span.important {
    color: $SECONDARY_LIGHT_COLOR;
  }
}

.center {
  text-align: center;
}

ul, ol {
  margin-top: 0;
  margin-bottom: 0;
}

figure {
  text-align: center;
}

figcaption {
  margin: 16px 0;
  text-transform: uppercase;
  color: $MAIN_COLOR;
  @include font-DIN-next-light($FONTSIZE_BASE_SM_UP);
}

main {
  .module-text {
    ol {
      padding-left: 0;
      @include font-DIN-next-light($FONTSIZE_BASE_SM_UP);
      counter-reset: item;

      > li {
        display: block;
        padding-left: 30px;
        margin: 0 0 10px;
        position: relative;

        &:before {
          position: absolute;
          left: 0;
          content: counter(item) ". ";
          counter-increment: item;
          color: $SECONDARY_LIGHT_COLOR;
        }
      }
    }

    ul {
      padding: 0;

      li {
        position: relative;
        padding-left: 30px;
        margin-bottom: 10px;
        list-style: none;
        @include font-DIN-next-light(remcalc(18px), remcalc(19px));

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          @extend .dolle-icon;
          content: $icon-arrow_right_mini;
          display: inline-block;
          width: 14px;
          font-size: 10px;
          line-height: 16px;
          color: $SECONDARY_LIGHT_COLOR;

          @include breakpoint(xs-only) {
            top: -2px;
          }
        }
      }
    }
  }

  dl {
    @include clearfix();

    dt {
      float: left;
      margin-right: 0.5em;
      @include font-DIN-next-regular(remcalc(16px), remcalc(26px));
      font-weight: normal;
      margin-bottom: 0;
    }

    dd {
      @include font-DIN-next-bold(remcalc(16px), remcalc(26px));
      text-align: right;
      width: 100%;
      margin-bottom: 0 !important;
      /*  Clearfix next dd-element */
      &::after {
        clear: left;
        content: " ";
        display: block;
      }
    }

  }

  table.contenttable {
    margin: 0 -30px;

    td, th {
      padding: 15px 30px;
      vertical-align: top;
    }
  }
}

a {
  outline: none;

  &.btn-popover {
    outline: none;
    margin-top: 0 !important;
    width: 25px !important;
    height: 21px;
    margin-right: 4px;
    padding: 0 5px 0 0;
    text-transform: none !important;
    line-height: 21px;
    text-align: center;
    color: #fff;
    background: url("../img/infolayericon_retina.png") no-repeat center center;
    background-size: 25px 20px;
    @include breakpoint(sm) {
      background: url("../img/infolayericon_desktop.png") no-repeat center center;
    }
  }

  &:visited {
    color: $SECONDARY_LIGHT_COLOR;
    text-decoration: none;
  }

  &.btn:visited {
    color: #FFF;
  }

  &.btn-default:visited {
    color: $MAIN_COLOR;
  }
}

img {
  max-width: 100%;
  height: auto;
}

hr {
  border-color: $MAIN_BORDER_COLOR;
}

a.button,
button {
  min-width: 40px;
  height: $BUTTON_LINE_HEIGHT_SM_UP;
  //border: 0;
  border-radius: 0;
  background-color: $BUTTON_BG_COLOR;
  color: $BUTTON_TEXT_COLOR;
  @include buttontext-config();
  line-height: 20px;
  outline: none;

  &:hover {
    background-color: $BUTTON_HOVER_BG_COLOR;
  }
}

a.button {
  padding-top: 10px;
}


.expandable-block {
  overflow: hidden;

  position: relative;

  height: 0;

  &.products-related {
    margin-top: 0;

    .product-items {
      margin-top: 2px;
    }
  }

  &:after {
    content: '';

    height: 100px;

    position: absolute;
    z-index: 100;
    right: 0;
    bottom: 0;
    left: 0;

    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%);
  }
}

.expandable-block-btn {
  display: block;

  margin-top: remcalc(30px);
  margin-right: auto;
  margin-left: auto;
}
