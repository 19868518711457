@include breakpoint(xs-only) {
	.model-overview-headline {
		padding-top: 20px;
	}
}

.btn-overview {
	display: none;
	width: 100%;
	text-align: left;
	margin-bottom: 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 35px;
	position: relative;

	@include breakpoint(xs-only) {
		display: block;
	}

	.icon-x_icon {
		float: right;
		line-height: inherit;
		position: absolute;
		right: 12px;
	}

	&:not([aria-expanded]), &[aria-expanded="false"] {
		.icon-x_icon::before {
			display: inline-block;
			transform: rotate(45deg);
		}
	}
}

.module-overview-container {
	@include breakpoint(sm) {
		display: block;
	}

	&:not(.js-enabled) {
		opacity: 0;
	}

	.module-model_overview {
		&:not(.js-enabled) {
			opacity: 0;
		}

		div {
			.model-overview-headline {
				padding-top: 0;
			}
		}

		.model-teaser-wrapper {

			@include breakpoint(sm) {
				display: block !important;
			}


			.model-teaser {
				margin-top: 10px;
				margin-bottom: 30px;

				@include breakpoint(xs-only) {
					display: none;
				}

				.model-teaser-inner {
					@include clearfix();

					.show-details {
						border: 1px solid $MAIN_BORDER_COLOR;
						@include clearfix();
						display: block;
						cursor: pointer;

						color: $MAIN_COLOR;

						&.active {
							border-color: $SECONDARY_LIGHT_COLOR;
							background-color: $SECONDARY_LIGHT_COLOR;
							color: #FFF;
						}

						> img {
							float: left;
							width: 95px;
							height: auto;
							padding: 1px;
						}
					}

					.detail-content {
						//@include make-row();

						clear: both;
						float: none;
						padding: 0;

						.model-teaser-detail-inner {
							@include clearfix();
							background-color: #eeeeed;

							p {
								@include font-DIN-next-light(remcalc(18px), remcalc(30px));
							}

							.btn {
								.icon-arrow_right_mini {
									font-size: remcalc(12px);
								}
							}
						}

						.model-teaser-img-wrapper {
							@include make-xs-column(12);
							@include make-sm-column(3);
							text-align: center;
							@include breakpoint(sm) {
								padding-left: 0;
								text-align: left;
							}
						}

						.model-teaser-text-wrapper {
							@include make-xs-column(12);
							@include make-sm-column(9);
							padding-bottom: 15px;
							padding-left: 10px;

							h2 {
								text-align: left;
							}

							.btn {
								text-transform: uppercase;
								@include font-DIN-next-bold(remcalc(16px), remcalc(28px));
								vertical-align: text-bottom;
								line-height: 32px;
								height: 40px;

								> span {
									margin-left: 8px;
								}
							}
						}
					}

					.model-teaser-headlines {
						display: flex;
						align-items: center;
						//justify-content: center;
						height: 95px;
						overflow: hidden;
						p {
							line-height: 20px;
							margin-bottom: 0;

							&.bold {
								text-transform: uppercase;
							}
						}
					}

					.detail-content {
						display: none;
					}
				}

				.model-teaser-headlines {
					float: left;
					padding: 14px;
					width: calc(100% - 95px);
				}
			}

			.mobile-model-teaser {
				@include make-xs-column(12);
				margin-top: 5px;
				margin-bottom: 5px;
				display: none;

				@include breakpoint(xs-only) {
					display: block;
				}

				.model-teaser-inner {
					.mobile-teaser-headline {
						display: none;
						width: 100%;
						text-align: left;
						background-color: #eeeeed;
						color: $MAIN_COLOR;
						border: none;
						text-transform: none;
						overflow: hidden;
						text-overflow: ellipsis;
						padding-right: 35px;
						position: relative;

						@include breakpoint(xs-only) {
							display: block;
						}

						.icon-x_icon {
							float: right;
							line-height: inherit;
							position: absolute;
							right: 12px;
						}

						&:not([aria-expanded]), &[aria-expanded="false"] {
							.icon-x_icon::before {
								display: inline-block;
								transform: rotate(45deg);
							}
						}

						.headline-wrapper {
							.bold {
								text-transform: uppercase;
								@include font-DIN-next-bold(remcalc(16px), remcalc(28px));
							}
						}
					}
				}

				.detail-content {
					padding: 15px 12px 20px;
					background-color: #eeeeed;
					overflow: hidden;

					.model-teaser-detail-inner {
						.model-teaser-text-wrapper {
							> *:first-of-type {
								margin-top: 0;
							}
						}

						.btn {
							overflow: hidden;
							text-overflow: ellipsis;
							position: relative;
							padding-right: 30px;
							width: 100%;

							.icon-arrow_right_mini {
								font-size: remcalc(15px);
								position: absolute;
								right: 12px;
								line-height: inherit;
							}
						}
					}
				}
			}
		}
	}






	.model-teaser-detail {
		clear: both;
		float: none;

		@include breakpoint(xs-only) {
			display: none !important;
		}

		.model-teaser-detail-inner {
			@include clearfix();
			margin: 0 15px 30px;
			background-color: #eeeeed;

			p {
				@include font-DIN-next-light(remcalc(18px), remcalc(30px));
			}

			.btn {
				.icon-arrow_right_mini {
					font-size: remcalc(12px);
				}
			}
		}

		.model-teaser-img-wrapper {
			@include make-xs-column(12);
			@include make-sm-column(3);
			text-align: center;
			@include breakpoint(sm) {
				padding-left: 0;
				text-align: left;
			}
		}

		.model-teaser-text-wrapper {
			@include make-xs-column(12);
			@include make-sm-column(9);
			padding-bottom: 15px;
			padding-left: 10px;

			h2 {
				text-align: left;
			}

			.btn {
				text-transform: uppercase;
				@include font-DIN-next-bold(remcalc(16px), remcalc(28px));
				vertical-align: text-bottom;
				line-height: 32px;
				height: 40px;

				> span {
					margin-left: 8px;
				}
			}
		}
	}
}

