.btn-gray, a.btn-gray, button.btn-gray {
    @extend .btn;
    background-color: $gray-base;
    border-color: $gray-base;

    &:hover {
        background-color: $gray-darker;
        border-color: $gray-darker;
    }
}

.uc-embedding-buttons {
    .uc-embedding-more-info {
        background: $MAIN_LIGHT_COLOR;
        color: $MAIN_COLOR;

        &:hover {
            background: $MAIN_COLOR_HOVER;
        }
    }

    .uc-embedding-accept {
        &:hover {
            color: $btn-primary-color;
        }
    }
}