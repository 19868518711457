.module-navigation {
	position: relative;
	z-index: 2;
	padding: 0;
	box-shadow: 5px 8.66px 30px 0 rgba(0, 0, 0, 0.2);



	.collapsing,
	.collapse {
		.content-holder {
			padding: 20px 0;

			> .nav {
				> li {
					@include make-md-column(3);

					@media (max-width: $screen-sm-max) {
						.lvl-1 {
							columns: 2;
							column-gap: remcalc($COLUMN_GAP);
							> li {
								position: relative;
								> a {
									padding-right: 0;
									text-transform: uppercase;
									@include font-DIN-next-light(remcalc(16px));
									margin-left: 14px;
								}
								&:before {
									position: absolute;
									top: 9px;
									line-height: 19px !important;
								}
							}
						}
					}
					> a {
						display: block;
						text-transform: uppercase;
						@include font-DIN-next-regular(remcalc(20px));
						line-height: remcalc(24px);
						border-bottom: 1px solid $MAIN_BORDER_COLOR;
						margin-bottom: 20px;
						padding-left: 0;
					}

					.lvl-1 {
						li {
							list-style: none;

							&:before {
								@extend .dolle-icon;
								content: $icon-arrow_right_mini;
								display: inline-block;
								width: 14px;
								font-size: 10px;
								line-height: 23px;
								color: $SECONDARY_LIGHT_COLOR;
								vertical-align: text-bottom;
							}

							a {
								padding-left: 0;
								display: inline-block;
								@include font-DIN-next-light(remcalc(20px));
								color: $MAIN_COLOR;
							}
						}
					}
				}
			}
		}
	}

	> a.btn {
		position: absolute;
		left: 15px;
		top: -35px;
		@include breakpoint(sm) {
			top: -40px;
		}
		border: 0;
		padding: 13px 12px 8px;
		text-transform: uppercase;
		@include font-DIN-next-medium(remcalc(16px));

		span {
			float: right;
			margin-top: 3px;
			margin-left: 4px;
			font-size: remcalc(10px);
		}

		&.collapsed {
			span {
				&:before {
					content: $icon-arrow_down;
				}
			}
		}
	}
}
