.module-background_image {
    padding-bottom: 15px;

    .background_image-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .textbox {
            position: absolute;
            z-index: 1;
            padding: 20px;
            margin: 15px;

            p:last-of-type {
                margin-bottom: 0;
            }

            h1, h2, h3, h4, h5, h6 {
                &:first-of-type {
                    margin-top: 0;
                }
            }

            .button-wrapper {
                margin-top: 12px;
            }
        }

        &.position-x-left {
            align-items: flex-start;
        }

        &.position-x-right {
            align-items: flex-end;
        }

        &.position-x-center {
            align-items: center;
        }

        &.position-y-top {
            justify-content: flex-start;
        }

        &.position-y-bottom {
            justify-content: flex-end;
        }

        &.position-y-center {
            justify-content: center;
        }
    }
}
