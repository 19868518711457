.module-breadcrumb {
  background-color: transparent !important;


  .breadcrumb-wrapper {
    @include make-xs-column(12);
    @include make-md-column(6);
    @include breakpoint(sm) {
      width: 100%;
    }

    padding: 25px 15px 6px;

    background-color: $BODY_BG_COLOR;
  }


  ul {
    padding: 0;

    list-style-type: none;


    li {
      float: left;

      margin-right: 8px;

      text-transform: uppercase;

      line-height: remcalc(22px);


      &.current {
        @include font-DIN-next-light(remcalc(16px), remcalc(22px));
        color: $MAIN_COLOR;
      }


      a {
        @include font-DIN-next-bold(remcalc(16px), remcalc(22px));
      }


      span {
        color: $MAIN_COLOR;
        font-size: remcalc(14px);
      }
    }
  }
}
