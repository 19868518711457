.module-static_map {

	.module-static-map-wrapper {
		@include make-xs-column(12);

		.overlay {
			padding: 10px 20px 20px;
			background-color: #eeeeed;
			text-align: center;
			margin-bottom: 0;
		}

		> img {
			margin-top: 1px;
		}
	}

}