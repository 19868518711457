.module-colored_textbox {
    padding-bottom: 15px;

    .textbox {
        padding: 20px;

        p:last-of-type {
            margin-bottom: 0;
        }

        h1, h2, h3, h4, h5, h6 {
            &:first-of-type {
                margin-top: 0;
            }
        }
    }

    ul, ol {
        @extend .check-list;
    }
}