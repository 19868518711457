#biu-configurator-inner {
  position: relative;
  min-height: 575px;

  .start-new {
    font-size: .8rem;
    cursor: pointer;

    &:hover, &:focus {
      color: #000;
    }
  }

  #biu-overlay {
    width: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    top: 0;
    left: 0;
    z-index: 100;

    #biu-overlay-inner {
      width: 90%;
      background: #fff;
      margin: 25px auto;
      padding: 15px;
      text-align: center;
      box-shadow: 0 0 10px #ccc;

      > h3 {
        @include font-DIN-next-bold(remcalc(24px), remcalc(30px));
      }

      > .row {
        margin: 0;
      }

      div.col-xs-4 {
        cursor: pointer;
        border: 1px solid transparent;
        padding-top: 15px;
        padding-bottom: 15px;
        color: $MAIN_COLOR;
        @include font-DIN-next-regular(remcalc(16px));

        > span {
          margin-top: 20px;
          display: inline-block;
        }

        &:first-of-type {
          border: 1px solid $SECONDARY_LIGHT_COLOR;

          > span {
            // max width = image width
            max-width: 220px;
            display: block;
            padding: .5em;
            margin: 10px auto 0;
            border: 1px solid $SECONDARY_LIGHT_COLOR;
            color: $SECONDARY_LIGHT_COLOR;

            &:hover, &:focus {
              background: $SECONDARY_LIGHT_COLOR;
              color: #fff;
            }
          }
        }
      }

      > div.col-xs-4:hover {
        text-decoration: underline;
      }

      #biu_code {
        font-weight: lighter;
        width: 30%;
        float: none;
        display: inline-block;
        margin: 0 1em 0 0;
        vertical-align: bottom;
      }

      img {
        display: block;
        margin: 0 auto .5em;
      }

      @media(max-width: $screen-sm-max) {
        width: 95%;

        .col-xs-4 {
          padding: 5px;
        }
      }
    }
  }
}

.configuration-container {
  margin: 0 auto;
  position: relative;
  padding: 0;

  img {
    max-width: 99%;
  }

  button.btn.btn-primary {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;

    > span {
      font-family: 'DIN Next W02 Bold', sans-serif;
      font-size: .88889rem;
      line-height: 1.2;
      // TODO @include font-DIN-next-bold(remcalc(24px), remcalc(30px));
    }

    > i {
      margin-left: 6px;
      font-size: 14px;
    }
  }
}

.configuration {
  position: relative;
  z-index: 2;

  .width {
    float: left;
    width: 100%;
    margin: 0;
  }

  .ui-slider {
    background: #888;
    position: relative;
  }

  .ui-slider-handle {
    position: absolute;
    cursor: grab;
    border-radius: 50%;

    &.fa-arrows-alt-h:before {
      color: $SECONDARY_LIGHT_COLOR;
      font-size: 1.3em;
    }

    &.nopulse {
      box-shadow: none;
      animation: none;
    }

    &:focus {
      outline: none;
      cursor: grabbing;
    }
  }

  #width-slider {
    float: right;
    height: 1px;
    margin: 10px 0 24px;

    .ui-slider-handle {
      top: -11px;
    }
  }

  #length-slider {
    width: 1px;
    margin: 0 auto;

    .ui-slider-handle {
      left: -11px;
      transform: rotate(90deg);
    }
  }

  .element, .rail, .beam {
    border: 1px solid #ccc;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .rail {
    cursor: pointer;
    background-color: #ddd;
    transition: left 0.2s;
    width: 5px;
    z-index: 990;

    &:hover {
      border-color: #aaa;
      background-color: #ccc;
    }
  }

  .beam {
    cursor: pointer;
    transition: width 0.2s;
    border-color: #ccc;
    height: 5px;
    overflow: visible;
    z-index: 980;
    // default left offset is the rail's width - border
    left: 4px;
    background-color: #fff;

    .beam-inner {
      background-color: #ddd;
      border: 1px solid #ccc;
      margin: 0 auto;
      width: 80%;
      height: 11px;
      position: relative;
      top: -4px;
    }

    &:hover {
      border-color: #aaa;

      .beam-inner {
        background-color: #ccc;
      }
    }
  }

  .element {
    transition: left 0.2s;

    .close {
      opacity: 1;
      color: #888;

      &:hover, &:focus {
        color: #000;
      }
    }

    &.removal-mark {
      background: rgba(250, 0, 0, 0.5);

      img {
        opacity: 0.5;
      }

      .close {
        color: $SECONDARY_LIGHT_COLOR;
        animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) infinite;
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

.visual-area {
  position: relative;
}

.greyed-out {
  background: #888;
  opacity: 0.2;
  position: absolute;
}

#grey-out-width {
  top: 0;
  right: 0;
  border-left: 1px solid #000;
}

#grey-out-length {
  bottom: 0;
  left: 0;
  border-top: 1px solid #000;
}

.config-row {
  pointer-events: none;
}

.config-row.row {
  margin: 0;
  max-width: 100%;
}

.dropzone {
  position: absolute;
  border: 2px dashed $brand-success;
  // border: 2px dashed #aaa;
  border-radius: 5px;
  width: 100px;
  height: 100px;
  top: 15px;
  left: 15px;
  transition: left 0.2s;
  text-align: center;

  .fas, .far {
    font-size: 3em;
    opacity: 0.75;
    color: $brand-success;
    // color: #aaa;
    vertical-align: middle;
  }

  &.drop-activated {
    background: rgba(92, 184, 92, 0.2);
  }

  &.drop-deactivated {
    border-color: $brand-danger;
  }
}

.elements {
  position: relative;
  z-index: 2;
  overflow: visible;

  > h2:first-of-type {
    margin-top: 0;
  }

  .price-box {
    border: 1px solid #eee;
    padding: .5em;

    button {
      display: block;
      width: 100%;
      margin-top: 1em;

      > span {
        font-family: 'DIN Next W02 Bold', sans-serif;
        font-size: .88889rem;
        line-height: 1.2;
        // TODO @include font-DIN-next-bold(remcalc(24px), remcalc(30px));
      }

      > i {
        margin-left: 6px;
        font-size: 14px;
      }
    }

    #price-formatted {
      color: $SECONDARY_LIGHT_COLOR;
      font-weight: bold;
      line-height: 1;
      margin: 0;
    }

    > span {
      color: inherit;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &.elements-mobile {
    position: fixed;
    background: #fff;
    left: 0;
    bottom: 0;
    border-top: 1px solid #ccc;

    h2 {
      margin-top: 10px;
    }

    #elements-list {
      padding: 0;
    }

    .element {
      img {
        float: none;
        width: 80%;
      }

      .product-name {
        position: static;
        font-size: .9em;
        line-height: 1em;
        margin-top: 5px;
        display: block;
      }
    }
  }
}

.element {
  border: 1px solid #fff;
  position: relative;
  margin-bottom: .5em;
  background: #fff;
}

.element:last-child {
  border-bottom: 0;
}

.elements {
  .element {
    cursor: move;
    touch-action: none;
    user-select: none;
    word-break: break-word;
    line-height: .7em;
    border: 1px solid #eee;
    padding: .25em;

    &:active, &:focus {
      border-color: #ddd;
      z-index: 999;
    }

    img {
      width: 65px;
      float: left;
      margin-right: .5em;
    }

    .product-name {
      position: absolute;
      top: 15%;
      font-size: .9rem;
      line-height: 1;
    }

    &.dragging {
      text-align: center;
      width: 45%;
      background: transparent;
      border: 0 none;
      position: absolute;
      z-index: 999;

      .product-name {
        display: none;
      }

      img {
        width: 80%;
        float: none;
        margin: 0 auto;
      }
    }
  }
}

.fas-popup .popup-header .close {
  right: 10px;
  top: -5px;
  position: absolute;
  font-size: 4em;
  font-weight: normal;
  font-family: inherit;
  opacity: .4;
}

.configuration .element {
  box-shadow: none;

  .icon-container {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 80px;
    margin-left: -40px;
    background: #fff;
    border: 1px solid #CCC;
    padding: .25em .5em;

    .config-menu-arrow {
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      bottom: -7px;
      left: calc(50% - 7px);
      width: 13px;
      height: 13px;
      border-bottom: 1px solid #CCC;
      border-right: 1px solid #CCC;
      background-color: #FFF;
    }
  }

  .widget-info {
    cursor: pointer;
    color: #888;
    font-size: 22px;
    line-height: 1;
    border-right: 1px solid #CCC;
    padding-right: 9px;
    text-shadow: 0 1px 0 #fff;

    &:focus, &:hover {
      color: #000;
    }
  }

  .fa-exclamation-triangle {
    position: absolute;
    top: 5px;
    left: 0;
    color: orange;
    cursor: pointer;
  }

  .widget-info, .fas, .far, .icon-container {
    display: none;
  }

  &:hover, &:focus {
    .widget-info, .fas, .far, .icon-container {
      display: initial;
    }
  }
}

.fas-popup {
  position: fixed;
  background: #fff;
  border: 1px solid #eeeeed;
  top: 10%;
  left: 50%;
  padding: 5px 10px;
  font-size: .8em;
  line-height: 1.2em;
  width: 100%;
  z-index: 999;
}

.fas-popup h3 {
  margin-top: 5px;
}

.fas-popup ul {
  padding-left: 1.5em;
  float: left;
}

.fas-popup-info {
  width: 700px;
  margin-left: -350px;
}

.fas-popup .subtitle {
  color: #666;
}

.fas-popup img.pull-left {
  margin-right: 5px;
}

.fas-popup-forward {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .popup-content {
    padding: 5%;
    float: left;

    > div {
      overflow-y: scroll;
    }

    .popup-element {
      h3 {
        margin-bottom: 0;
      }

      img.pull-left {
        width: 80px;
        height: auto;
        margin-right: 15px;
      }

      p.subtitle {
        font-size: 1.1em;
      }

      span.pull-right {
        font-size: 1.5em;
      }
    }

    label {
      font-weight: normal;
      cursor: pointer;
      font-size: .9rem;
    }

    input[type="checkbox"] {
      height: auto;
      margin: 0 5px 0 0;
    }

    #forward-configured-price {
      margin: 1rem 0 0;
      font-size: 2rem;
      line-height: 2rem;

      strong {
        font-size: inherit;
        line-height: inherit;
      }
    }

    .checkout-info p {
      font-size: .7rem;
    }

    button.btn.btn-primary {
      background: green;
      border-color: green;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857;
      width: 50%;

      > span {
        font-family: 'DIN Next W02 Bold', sans-serif;
        font-size: .88889rem;
        line-height: 1.2;
        // TODO @include font-DIN-next-bold(remcalc(24px), remcalc(30px));
      }

      > i {
        margin-left: 6px;
        font-size: 14px;
      }
    }
  }
}

.slider-tooltip {
  position: absolute;
  white-space: nowrap;
  border: 1px solid $SECONDARY_LIGHT_COLOR;
  background: #fff;
  color: inherit;
  text-align: center;
  padding: 0 3px;
  right: 15px;

  @media screen and (max-width: $screen-xs-max) {
    display: none;
  }
}

.room-width, .price-contents {
  font-size: .8em;
  padding: 0;

  @media(max-width: $screen-sm-max) {
    font-size: 1em;
  }
}

.product-name {
  font-size: .7em;
  padding: 0;
}

.room-width {
  color: #000;
  display: inline-block;
  margin-bottom: 5px;
}

.width-container .room-width {
  transform: rotate(270deg);
  width: 200px;
  position: absolute;
  top: 50%;
  left: -110px;
}

#elements-list {
  overflow: visible;
  height: 100%;
  position: relative;
}

#biu-code-container {
  font-size: .8em;
  line-height: 2.5em;
  margin-bottom: .5em;
  padding-bottom: 1em;
  border-top: 1px solid #ddd;
  padding-top: 1em;
}

.generated-code > div {
  margin-top: 20px;
}


.popup-element {
  margin-bottom: 10px;
  padding: 3px 0;
  border-bottom: 1px solid #ccc;
}

.popup-header {
  margin-bottom: 10px;
  padding: 3px 0;
}

.popup-header h3 {
  margin: 0;

  @media screen and (max-width: 767px) {
    font-family: 'DIN Next W02 Medium', sans-serif;
    font-weight: normal;
  }
}


.fas-popup h3.element-price {
  clear: both;
}

.popup-footer {
  margin-top: 10px;
  padding: 10px 1em 0;
}

.price-popup, .explanation-popup {
  padding: 1em 0;
  max-width: 770px;
  margin-left: -375px;
  min-height: 300px;
  overflow: hidden;

  .popup-content {
    max-height: 500px;
    overflow-y: scroll;
  }

  .popup-header, .popup-element {
    margin-bottom: 10px;
    padding: 0 1em 10px;
    border-bottom: 1px solid #ccc;

    h3 {
      a {
        color: inherit;
      }

      span.pull-right {
        font-weight: lighter;
        font-size: 1.33333rem;
      }
    }

  }
}

.explanation-popup {
  @extend .module-text
}

// price styling
.configured-price h3, h3.element-price {
  font-weight: lighter;
  font-size: 1.33333rem;
}

#room-width-value, #room-length-value, #module-count {
  font-weight: bold;
  color: #000;
  float: none;
  display: inline-block;
  line-height: 1em;
  height: 20px;
  width: 40px;
  text-align: center;
  border: 0 none;
  box-shadow: none;
  margin: 0;
  padding: 0;
}

.save-code {
  margin: 1em 0;
  font-size: .9rem;

  a {
    color: inherit;

    span {
      color: $SECONDARY_LIGHT_COLOR;
    }
  }
}

.popup-back {
  border-bottom: 1px solid #ccc;
}

.generated-code {
  font-weight: bold;
  color: #000;
}

.generated-code-container {
  p {
    font-size: .9rem;
  }
}

#biu-fullscreen-loader {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  text-align: center;

  .uil-ripple {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 128px;
    height: 128px;
    margin-left: -64px;
    margin-top: -64px;
    background: none;

    div {
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      margin: 0 0 0 0;
      border-style: solid;
      border-width: 0;
      border-radius: 50%;
      -webkit-animation: uil-ripple 2s linear infinite;
      animation: uil-ripple 2s linear infinite;
    }

    div:nth-of-type(1) {
      border-color: $MAIN_COLOR;
    }

    div:nth-of-type(2) {
      border-color: $SECONDARY_LIGHT_COLOR;
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
    }
  }
}

#biu-popup-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 100;
}

.wall-selection {
  padding: 1em 1em .5em;
  border: 1px solid #ddd;

  #special_plugs {
    display: none;
  }

  .stylable-input:not(.selectproxy).checked {
    font-size: .9rem;
  }
}

.wall-selection, .additional-info {
  p {
    font-size: .9rem;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/*
 * Responsive styling
 */
@media(max-width: $screen-xs-max) {

  #biu-code-container {
    > .col-xs-12 {
      padding: 0;
    }
  }

  #biu-configurator-inner {
    #biu-overlay #biu-overlay-inner #biu_code {
      width: 60%;
    }

    .start-new {
      border-bottom: 1px solid #ccc;
      padding: .5em 0;
      margin: 0 0 1em 15px;
      font-size: .9rem;
    }
  }


  .elements.elements-mobile {
    .element .product-name .product-price {
      display: none;
    }

    .fa-chevron-down, .fa-chevron-up {
      color: #aaa;
      margin-right: .5em;
      margin-top: 1px;
    }
  }
  .configuration {
    .ui-slider-handle.fa-arrows-alt-h:before {
      font-size: 2.2em;
    }

    #width-slider {
      .ui-slider-handle {
        top: -15px;
      }
    }

    #length-slider {
      .ui-slider-handle {
        left: -15px;
      }
    }
  }

  .fas-popup-info, .price-popup, .explanation-popup {
    width: 100%;
    left: 0;
    margin-left: 0;
    min-height: 0;
    top: 0;
    height: 100%;
  }

  .fas-popup-info {
    .popup-header {
      height: 35px;
    }

    img.pull-left {
      float: none !important;
      margin: 1em auto;
      display: block;
    }
  }

  .popup-footer {
    margin-top: 0;
    border-top: 2px solid #ccc;
  }

  .popup-element {
    img.pull-left {
      width: 75px;
    }

    .price-popup-product-desctiption {
      float: left;
      width: calc(100% - 80px);
    }

    a.pull-right {
      display: none;
    }
  }

  .price-popup .popup-content, .explanation-popup .popup-content {
    max-height: calc(100% - 100px);
  }

  .fas-popup-forward {
    overflow-y: scroll;
  }

  .width-container .room-width {
    left: -80px;
  }

  .fas-popup .popup-header .close {
    top: 0;
  }

  .room-width {
    margin-bottom: 0;
  }

  .save-code {
    margin-bottom: 3em;
  }
}


@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 $SECONDARY_LIGHT_COLOR;
  }
  20% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 $SECONDARY_LIGHT_COLOR;
    box-shadow: 0 0 0 0 $SECONDARY_LIGHT_COLOR;
  }
  20% {
    -moz-box-shadow: 0 0 0 15px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 15px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}


/**
 * A ring growing from the center, fading in and out again while growing.
 **/
@keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
    border-width: 0;
  }

  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
    border-width: 8px;
  }

  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
    border-width: 0;
  }
}


