.no-padding {
    padding-left: 0;
    padding-right: 0;
}

.biu-gallery-wrapper {
    /*padding: 35px 0;
    @include breakpoint(md) {
        padding: 50px 0;
    }
    @include breakpoint(lg) {
        padding: 150px 0;
    }*/
    padding: 0;
    height: 100%;
    width: 100%;
    position: relative;
}

.module-biu_gallery_container {
    /*height: 194px;*/
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    /*@include breakpoint(sm) {
        height: 405px;
    }
    @include breakpoint(md) {
        height: 528px;
    }
    @include breakpoint(lg) {
        height: 658px;
    }*/

    .biu-headline p {
        font-size: 42px;
        line-height: 44px;
        font-weight: bold;
        margin: .25em;
        max-width: 500px;

        @include breakpoint(sm) {
            margin: 50px 0;
        }
    }

    .biu-teaser-wrapper {
        @include container();

        .biu-teaser {
            float: right;
            position: relative;
            width: 100%;
            padding: 2em 1em 1em;
            margin-top: 5em;
            margin-right: 0;
            background-color: rgba(255,255,255,0.8);
            text-align: left;

            &.text-position-left {
                float: left;
            }

            @include breakpoint(sm) {
                width: calc(50% + 120px);
                min-height: 130px;
                &.text-position-bottom{
                    margin-top: 25%;;
                }
            }

            @include breakpoint(md) {
                width: calc(50% - 120px);
                margin-right: 105px;

                &.text-position-left {
                    float: left;
                    margin-left: 75px;
                    margin-right: 0;
                }
                &.text-position-bottom{
                    margin-top: 25%;;
                }
            }

            @include breakpoint(lg) {
                &.text-position-bottom {
                    margin-top: 22em;
                }
            }

            h2 {
                text-align: left;
            }

            h3 {
                @include font-DIN-next-bold(remcalc(24px), remcalc(30px));
            }

            h2, h3, h4, h5, h6 {
                margin-top: 0;
                > a {
                    color: $MAIN_COLOR;
                    text-decoration: none;
                }
            }

            //@include secondary-headline-config();
            display: none;

            @include breakpoint(sm) {
                display: block;
            }

            strong, b {
                @include font-DIN-next-bold();
            }
        }
    }

    .slick-slider {
        z-index: 999;
    }

    .slick-list,
    .slick-track {
        height: 100%;
    }

    .slick-slide {
        position: relative;
    }

    .slick-dots {
        @include breakpoint(md) {
            top: 620px;
        }
        li button {
            width: 16px;
            height: 16px;
            border: 2px solid $BG_COLOR;
        }
    }

    .slick-prev, .slick-next {
        @include breakpoint(md) {
            display: block !important;
            width: 60px;
            height: 45px;
            top: 50%;
            margin-top: -22px;

            &:before {
                font-size: 36px;
            }
        }
    }

    .module-biu-img {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: -1;
        background-repeat: no-repeat;
        background-size: cover;
    }
}


.module-biu_image + .module {
    margin-top: 130px;
    @include breakpoint(md) {
        margin-top: 230px;
    }
    @include breakpoint(lg) {
        margin-top: 230px;
    }
}

@include breakpoint(xs-only) {
    .module-biu_gallery_container {
        .slick-dots {
            margin-bottom: 36px;
        }
        .slick-active {
            margin-right: 10px;
        }
    }
}

.biu-gallery-additional-html {
    z-index: 999;
    padding: 1em 2em;
    @include breakpoint(xs-only) {
        padding-left: 0;
    }
}

// TODO do not render instead of hide
.module-biu_gallery_navigation {
    padding: 0;
    margin-top: 1em;
    z-index: 999;

    & + .module {
        clear: both;
    }
    .biu-headline {
        display:none;
    }

    .module-biu_image {
        padding: 0 15px 0 0;
        height: 200px;

        .module-biu-img {
            height: 200px;
            cursor: pointer;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            border: 2px solid transparent;
        }

        &.slick-current .module-biu-img {
            border-color: $SECONDARY_LIGHT_COLOR;
        }

        &:not(.slick-current) .module-biu-img:hover {
            border-color: $SECONDARY_LIGHT_COLOR;
        }
    }
    @include breakpoint(xs-only) {
        .module-biu_image {
            height: auto;
        }
    }
}

body.is-mobile {

    .module-biu_gallery_navigation {
        width: 100%;
        display: none;

        .module-biu_image {
            height: auto;
        }
    }

    .biu-gallery-additional-html {
        padding-left: 0;
    }

    .module-biu_gallery_container {
        .slick-dots {
            margin-bottom: 36px;
        }

        .slick-active {
            margin-right: 10px;
        }

        .biu-teaser-wrapper {
            display: none;
        }
    }
}
