.module-teaser_container {
	//padding-bottom: remcalc(70px);
	padding-bottom: 30px;

	.module-teaser-header {
		@include make-sm-column(12);
		text-align: center;
		//margin-bottom: remcalc(40px);
		h2, h3 {
			text-transform: uppercase;
			margin-bottom: 20px;
		}

		.teaser-header-subheadline {
			margin-bottom: 46px;
		}

	}

	.module-teaser {
		border: 1px solid $MAIN_BORDER_COLOR;
		@include breakpoint(xs-only) {
			@include clearfix();
			margin-bottom: 30px;
		}
		@include breakpoint(sm-only) {
			@include clearfix();
			margin-bottom: 30px;
		}

		.teaser-headline > a {
			color: $MAIN_COLOR;
			text-decoration: none;
		}

		.teaser-text-wrapper {
			padding: 20px;
			width: 50%;
			float: left;
			@include breakpoint(md) {
				width: 100%;
				float: none;
			}

			.teaser-headline {
				@include font-DIN-next-regular(remcalc(20px));
			}

			h3,
			h4 {
				margin-top: 0;
				margin-bottom: 20px;
			}
		}

		.teaser-image-wrapper {
			padding: 1px;
			@include breakpoint(sm) {
				//height: 135px;
			}
			width: 50%;
			float: left;
			@include breakpoint(md) {
				height: 250px;
				width: 100%;
				float: none;
			}
			a {
				display: block;

				img {
					width: 100%;
					@include breakpoint(sm) {
						//height: 135px;
					}
					@include breakpoint(md) {
						height: 250px;
					}
				}
			}
		}

		&.module-teaser_1 {
			@media (max-width: $screen-sm-max) {
				position: relative;

				.teaser-text-wrapper {
					> a {
						//position: absolute;
						//bottom: 20px;
					}
				}
			}
		}

		&.module-teaser_3 {
			@include clearfix();
			position: relative;
			//padding: 1px;
			.teaser-text-wrapper {
				padding: 20px 20px 20px 0;
				@media (max-width: $screen-sm-max) {
					padding: 10px;
				}
				width: 50%;
				float: left;
				height: 100%;

				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				.teaser-text-content {
					height: 75%;
				}

				h2,
				h3,
				h4 {
					margin-top: 0;
					text-transform: uppercase;
				}

				a {
					position: absolute;
					left: 0;
					@media (max-width: $screen-sm-max) {
						left: 10px;
					}
					bottom: 20px;
				}
			}
			.teaser-image-wrapper {
				width: 50%;
				float: left;

				@include breakpoint(md) {
					height: 363px;
				}

				img {
					width: auto;
					max-height: 100%;
				}

				.new,
				.promotion {
					position: absolute;
					bottom: 1px;
					padding: 0 10px;
					left: 1px;
					@include font-DIN-next-light(remcalc(22px), remcalc(50px));
					@include breakpoint(sm) {
						@include font-DIN-next-light(remcalc(24px), remcalc(54px));
					}
				}
				.new {
					color: #FFF;
					background-color: $SECONDARY_LIGHT_COLOR;
				}

				.promotion {
					color: $MAIN_COLOR;
					background-color: $MAIN_LIGHT_COLOR;
					> span {
						color: $SECONDARY_LIGHT_COLOR;
					}
				}

			}
		}

		&.module-teaser_2 {
			border: 0;
			border-bottom: 2px solid $MAIN_BORDER_COLOR;
			text-align: center;

			.teaser-image-wrapper {
				border: 1px solid $MAIN_BORDER_COLOR;
				padding: 1px;
				height: auto;

				img {
					height: auto;
					max-height: 250px;
				}
			}

			@media (max-width: $screen-sm-max) {
				border: 0;

				.teaser-image-wrapper {
					width: 25%;
				}

				.teaser-text-wrapper {
					padding: 0 20px;
					width: 75%;
					text-align: left;

					h3 {
						margin-top: 0;
					}
				}
			}

		}
	}
	@media (max-width: $screen-sm-max) {
		> .col-xs-12 {
			&:last-child {
				.module-teaser {
					margin-bottom: 0;
				}
			}
		}
	}
}

.module-teaser_with_text {
	cursor: pointer;

	.hidden-teaser-content {
		display: none;
	}

	img {
		border: 1px solid $MAIN_BORDER_COLOR;
		padding: 1px;
		width: 100%;
		@media (max-width: $screen-sm-max) {
			width: 100%;
			height: auto;
		}
	}

	p {
		text-align: center;
		margin: remcalc(20px) 0 remcalc(30px);
		text-transform: uppercase;
	}

	&.active {
		img {
			border: 1px solid $SECONDARY_LIGHT_COLOR;
		}
		p {
			color: $SECONDARY_LIGHT_COLOR;
		}
	}
}



.module-quadruple_slider {
	padding-top: 20px;
}



.module-quadruple_slider-teaser-text {
	@include make-row();
	text-align: center;
	.col-xs-12 {
		margin-top: 10px;
	}
}



.module-single_slider {
	.module-teaser_3 {
		min-height: 200px;
	}
}



/* Styles for Dolle-Shelving only */
#dolle-shelving .module-teaser_container {
	.module-teaser {
		&.module-teaser_1 {
			@include breakpoint(xxs-only) {
				.teaser-text-wrapper {
					.teaser-headline {
						& + p {
							display: none;
						}
					}
				}
			}
		}
	}
}