.module-contact-form {
  padding-top: 48px;
  background-color: $BODY_BG_COLOR;
  @include clearfix();

  form {
    > .form-group {
      padding: 0 15px;
    }

    .row {
      margin-bottom: 22px;
      margin-left: 0;
      margin-right: 0;

      &:not(.actions) {
        .form-group {
          margin-bottom: 0;
        }
      }
    }
  }

  textarea {
    min-height: 300px;
  }

  .form-error-message {
    p {
      color: $SECONDARY_LIGHT_COLOR;
    }
  }

  .form-salutation, .row.salutations {
    div.radio-wrapper {
      width: 50%;
      float: left;
      padding-left: 15px;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  .checkbox-wrapper-3-3, .row.topics {
    div.checkbox {
      @include breakpoint(md) {
        width: 33.3333%;
        float: left;
      }
    }
  }

  .btn {
    width: 100%;
    margin-bottom: 15px;
    @include breakpoint(md) {
      margin-bottom: 0;
    }
  }
}