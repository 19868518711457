.module-structure {
  .col-md-2dot4 {
    @extend .col-xs-12;
  }

  @media screen and (min-width: $screen-md) {
    .col-md-2dot4 {
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}


