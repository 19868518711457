// wtf: ja, genau hier würde ich globale Elements anpassen oO
header, footer {
	z-index: 1;
	position: relative;
}

.intro-slider-wrapper {
	/*padding: 35px 0;
	@include breakpoint(md) {
		padding: 50px 0;
	}
	@include breakpoint(lg) {
		padding: 150px 0;
	}*/
	padding: 0;
	height: 100%;
	width: 100%;
	position: relative;
}

.module-intro_image_small,
.module-intro_image_container {
	height: 386px;
	//max-width: 1600px;
	margin: 0 auto;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	
	&.smaller-gradient {
	   height: auto;
	}

	@include breakpoint(sm) {
		height: 455px;
		&.smaller-gradient {
           height: auto;
        }
	}
	@include breakpoint(md) {
		//top: 38px;
		height: 900px;
		&.smaller-gradient {
           height: auto;
        }
	}
	@include breakpoint(lg) {
		height: 1200px;
		&.smaller-gradient {
           height: auto;
        }
	}



	.intro-teaser-wrapper {
		@include container();



		.intro-teaser {
			float: right;

			position: relative;

			width: 100%;

			padding: 0 20px 0;
			margin-top: 155px;
			margin-right: 0;

			background-color: $BODY_BG_COLOR;

			text-align: left;

			@include breakpoint(sm) {
				width: calc(50% + 120px);
				min-height: 130px;

				margin-top: 160px;
			}

			@include breakpoint(md) {
				width: calc(50% - 120px);

				margin-top: 200px;
				margin-right: 75px;
			}

			@include breakpoint(lg) {
				margin-top: 300px;
			}



			h2 {
				text-align: left;
			}

			h3 {
				@include font-DIN-next-bold(remcalc(24px), remcalc(30px));
			}

			h2, h3, h4, h5, h6 {
				> a {
					color: $MAIN_COLOR;
					text-decoration: none;
				}
			}



			> p {
				//@include secondary-headline-config();
				display: none;

				@include breakpoint(sm) {
					display: block;
				}
			}

			strong, b {
				@include font-DIN-next-bold();
			}



			div.price-wrapper-teaser {
				@include clearfix();



				strong, b {
					@include font-DIN-next-bold(remcalc(20px), remcalc(24px));
				}



				b:last-child,
				strong:last-child,
				span {
					float: right;

					@include font-DIN-next-bold(remcalc(24px), remcalc(24px));
					color: $SECONDARY_LIGHT_COLOR;
					text-align: right;
				}
			}



			.intro-button-wrapper {
				text-align: right;
				@include font-DIN-next-bold(remcalc(16px));
				margin-top: 20px;

				a {
					@include font-DIN-next-medium(remcalc(16px));

					margin-right: -20px;
					text-transform: uppercase;

					&:after {
						margin-left: 20px;
						font-weight: bold;
					}
				}

				.btn {
					padding: 10px 12px 9px;

				}
			}
		}
	}


    .module-video .embed-responsive {
        margin: 0 auto;
    }

	.slick-list,
	.slick-track {
		height: 100%;
	}

	.slick-slide {
		position: relative;
	}

	.slick-dots {
		@include breakpoint(md) {
			top: 620px;
		}
		li button {
			width: 16px;
			height: 16px;
			border: 2px solid $BG_COLOR;
		}
	}

	.slick-prev, .slick-next {
		display: none !important;

		@include breakpoint(md) {
			display: block !important;

			width: 60px;
			height: 45px;

			top: 375px;

			&:before {
				font-size: 36px;
			}
		}
	}

	.module-intro-img {
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: -1;
		background-repeat: no-repeat;
		background-size: cover;
	}

	@include breakpoint(md) {
		&:after {
			content: '';
			height: 240px;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			background-image: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
			right: 0;
		}
		&.smaller-gradient:after {
		    height: 90px;
		    background-image: linear-gradient(0deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%);
		}
	}

}

.module-intro_image_small {
	height: 230px;
	@include breakpoint(md) {
		height: 600px;
	}
	@include breakpoint(lg) {
		height: 1000px;
	}
}

.module-intro_image_small + .module {
	margin-top: 130px;
	@include breakpoint(md) {
		margin-top: 230px;
	}
	@include breakpoint(lg) {
		margin-top: 230px;
	}
}

@include breakpoint(xs-only) {
	.module-intro_image_container {
		.slick-dots {
			margin-bottom: 36px;
		}
	}
}


.module-intro_image_container + .module {
	margin-top: 335px;
	// todo correct margin?

	@include breakpoint(sm) {
		margin-top: 405px;
	}

	@include breakpoint(lg) {
		margin-top: 535px;
	}
}
