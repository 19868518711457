.module-product_teaser_container {
    .product-teaser-wrapper {
        @include make-sm-column(12);
        margin-bottom: remcalc(40px);
        margin-top: remcalc(20px);

        h2 {
            margin-bottom: 20px;
        }

        p {
            text-align: center;
        }
    }
}

.product-teaser-slider-wrapper {
    @include make-sm-column(12);
    margin: 0 0 15px 0;
    padding-right: 0 !important;
    padding-left: 0 !important;
    padding-bottom: remcalc(55px);
    @include clearfix();

    .slick-track {
        display: flex;
        flex-direction: row;
    }

    &.slick-initialized {
        .slick-slide {
            display: flex;
            flex-direction: column;
        }
    }

    .slick-prev, .slick-next {
        top: 35%;
    }

    .slick-prev {
        left: 30px;
    }

    .slick-next {
        right: 30px;
    }
}

.module-product_teaser {
    padding: 0 15px;
    height: auto;

    &:not(.slick-slide) {
        @include make-xs-column(6);
        @include make-md-column(3);
    }

    .product-teaser-category {
        text-transform: uppercase;
        @include font-DIN-next-regular(remcalc(20px));

        margin: 0;

        a {
            color: $MAIN_COLOR;
        }
    }

    .product-teaser-image-wrapper {
        height: auto;
        width: 100%;
        border: 1px solid $MAIN_BORDER_COLOR;
        padding: 1px;
        flex-grow: 1;

        a {
            display: block;
            height: 100%;
            width: 100%;

            img {
                width: 100%;
                max-height: 100%;
                @include breakpoint(md) {
                    max-height: 361px;
                }
            }
        }

        margin-bottom: 20px;
    }

    .product-teaser-name {
        @include font-DIN-next-regular(remcalc(20px));
        color: $SECONDARY_LIGHT_COLOR;
        min-height: 48px;

    }

    .product-teaser-price {
        @include clearfix();
        @include font-DIN-next-bold(remcalc(18px));
        padding-bottom: 20px;
        border-bottom: 2px solid $MAIN_BORDER_COLOR;

        .price-label {
            float: left;
            color: $MAIN_COLOR;
        }

        .price {
            float: right;
            color: $SECONDARY_LIGHT_COLOR;
        }
    }

}