body > .lightbox-container {
	.overlay {
		background-color: #fff;
		cursor: pointer;

		&:after {
			content: '';

			cursor: pointer;

			position: fixed;
			top: 0;
			right: 0;

			width: 50px;
			height: 50px;

			background-image: url('../mage/gallery/gallery.png');
			background-position: -50px 0;
			background-position: -91px -14px;
		}
	}



	.lightbox {
		min-width: 50px;
		min-height: 50px;

		margin: 50px;
		border: 0;
		padding: 0;



		h3 {
			display: none !important;
		}



		.prev,
		.next {
			cursor: pointer;
			user-select: none;

			position: fixed !important;

			width: 50px;
			height: 50px;

			bottom: 50%;

			border: 0;

			color: transparent;
			font-size: 0;
		}

		.prev {
			left: 0;

			background-image: url('../mage/gallery/gallery.png');
			background-position: -95px -95px;
		}

		.next {
			right: 0;

			background-image: url('../mage/gallery/gallery.png');
			background-position: -175px -95px;
		}
	}
}
