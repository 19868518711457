* {
  -webkit-font-smoothing: antialiased;
}

html {
  height: 100%;
  @include font-default($FONTSIZE_BASE_SM_UP);
  @include breakpoint(xs-only) {
    font-size: $FONTSIZE_BASE_XS;
  }

  body {
    width: 100%;
    overflow-x: hidden;
    @include font-default($FONTSIZE_BASE_SM_UP);
    @include breakpoint(xs-only) {
      font-size: $FONTSIZE_BASE_XS;
    }

    main {
      color: $MAIN_COLOR;
      min-height: 300px;
      padding-top: 50px;
      @include breakpoint(md) {
        padding-top: 138px;
      }
      @include container();

      > .module {
        position: relative;
        background-color: $BODY_BG_COLOR;
        @include clearfix();
        @include make-row();
      }

      > .module:first-of-type.module-text {
        @include breakpoint(sm) {
          margin-top: 40px;
        }
      }

      p {
        &.red-button, &.grey-button {
          a {
            @extend .btn;
            @extend .btn-primary;
            text-transform: uppercase;
            @include font-DIN-next-medium(remcalc($BUTTON_TEXT_SIZE_SM_UP), remcalc($BUTTON_LINE_HEIGHT_SM_UP));

            &::after {
              @extend .dolle-icon;
              content: $icon-arrow_right_mini;
              font-size: remcalc(14px);
              padding-left: 20px;
            }
          }
        }

        &.grey-button {
          a, a:visited, a:active {
            background-color: $MAIN_LIGHT_COLOR;
            color: $MAIN_COLOR;
            border: 0 none;

            &:hover, &:focus {
              background-color: $MAIN_COLOR_HOVER;
              color: $MAIN_COLOR;
              outline: none;
            }
          }
        }
      }

      .module-pricedisclaimer {
        @include container();

        .textwrapper {
          @include make-row();

          margin-bottom: 30px;

          > p {
            @include make-xs-column(10);
            @include make-xs-column-offset(1);
            @include make-sm-column(8);
            @include make-sm-column-offset(2);

            @include font-DIN-next-regular(remcalc(16px), remcalc(24px));
            text-align: center;
          }
        }
      }

      .module-backtotop {
        padding: 0 0 remcalc(10px);
        @include breakpoint(md) {
          padding: remcalc(10px) 0 remcalc(20px);
        }
        text-align: center;

        #return-to-top {
          display: block;
          color: $MAIN_COLOR;
          position: relative;
          padding-top: 34px;
          font-size: 16px;

          &:before {
            content: $icon-arrow_up;
            @extend .dolle-icon;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            font-size: 12px;
            line-height: 40px;
            color: $SECONDARY_LIGHT_COLOR;
          }
        }
      }

      .module-text {
        padding-bottom: 15px;
        @include clearfix();

        h2 {
          text-transform: uppercase;
        }

        img {
          padding: 10px 20px;
        }
      }

      .module-images {
        @include breakpoint(sm-only) {
          float: none !important;
        }
        @include breakpoint(xs-only) {
          float: none !important;
        }

        figure + figure {
          margin-top: 15px;
        }
      }

      .error {
        color: $SECONDARY_LIGHT_COLOR;
        @include font-DIN-next-light(remcalc(16px));
      }

      .module-structure {
        .module-text {
          h4 {
            margin-top: 0;
          }
        }

        .module-images {
          @include make-row();
        }

        .col-md-9 {
          > .module.module-text,
          > .module.module-accordion_container {
            @include make-row();
          }
        }
      }

      .check-list {
        padding: 0;

        li {
          position: relative;
          padding-left: 30px;
          margin-bottom: 10px;
          list-style: none;
          @include font-DIN-next-light(remcalc(18px), remcalc(19px));

          &:before {
            position: absolute;
            left: 0;
            top: 0;
            @extend .dolle-icon;
            content: $icon-arrow_right_mini;
            display: inline-block;
            width: 14px;
            font-size: 10px;
            line-height: 16px;
            color: $SECONDARY_LIGHT_COLOR;

            @include breakpoint(xs-only) {
              top: -2px;
            }
          }
        }
      }
    }

    &.catalog-category-view {
      .module-pricedisclaimer {
        padding-left: 15px;
        padding-right: 15px;
        @include breakpoint(sm) {
          padding-right: 45px;
        }

        .textwrapper {
          padding-top: remcalc(15px);
          padding-bottom: remcalc(15px);

          > p {
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  body#dolle-shelving {
    main {
      .module-text {
        @include breakpoint(xxs-only) {
          h1 {
            @include font-DIN-next-medium(remcalc(36px), remcalc(45px));
          }
        }
      }
    }
  }
}

@media (max-width: $screen-sm) {
  body {
    @include text-tags() {
      word-break: break-all; // Fallback
      word-break: break-word;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
    }
  }
}