.module-model_search {

	.model-search-header {
		margin: 0 15px;

		.mobile-teaser-headline {
			display: none;
			width: 100%;
			text-align: left;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-right: 35px;
			position: relative;

			@include breakpoint(xs-only) {
				display: block;
			}

			.icon-x_icon {
				float: right;
				line-height: inherit;
				position: absolute;
				right: 12px;
			}

			&:not([aria-expanded]), &[aria-expanded="false"] {
				.icon-x_icon::before {
					display: inline-block;
					transform: rotate(45deg);
				}
			}
		}
	}

	.model-search-form {
		@include clearfix();
		padding: 20px 5px;
		margin: 15px;
		background-color: #eeeeed;

		@include breakpoint(sm){
			padding: 30px;
			display: block !important;
			height: auto !important;
		}



		p {
			margin: 0 15px;
			@include font-DIN-next-medium(remcalc(18px), remcalc(30px));
		}



		form {
			@include clearfix();
			margin-top: 10px;
		}



		.col-sm-8 {
			.form-group {
				@include make-sm-column(6);

				.popover-container {
					display: none;
				}

				.popover {
					max-width: none;

					padding: 0;
					border-color: #eee8e4;

					box-shadow: 0 5px 12px rgba(0,0,0,0.1);



					.arrow {
						border-color: transparent;
					}



					.popover-content {
						@include clearfix();

						display: block;

						position: relative;

						width: 274px;
						min-height: 90px;
						max-height: 33vh;

						padding: 0;

						@include breakpoint(sm) {
							width: 570px;
							min-height: 190px;
						}



						.text-wrapper {
							float: left;
							overflow: hidden;

							width: 100%;

							padding-right: calc(33% + 16px);



							.title {
								padding: remcalc(20px);
								margin: 0;

								text-transform: uppercase;
								@include font-DIN-next-regular(remcalc(18px));

								.icon-x_icon {
									cursor: pointer;

									margin-right: 10px;

									@include font-DIN-next-regular(remcalc(14px));
								}
							}



							.text {
								overflow-x: hidden;
								overflow-y: auto;

								height: auto;

								max-height: 200px;
								padding: remcalc(20px);
								padding-top: 0;

								@include font-DIN-next-light(remcalc(16px));
							}
						}



						.image-wrapper {
							overflow: hidden;

							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;

							width: 33.3%;

							text-align: right;

							background-color: #fff;

							img {
								width: 100%;
							}
						}
					}
				}
			}
		}

		.col-sm-4 {
			.form-group {
				@include make-sm-column(12);
			}
		}
		.form-group {

			.btn {
				margin-top: 26px;
				width: 100%;
				text-align: center;

				> span {
					margin-left: 8px;
					font-size: remcalc(13px);
				}
			}
		}
	}
}
