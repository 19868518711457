.module-accordion_container {
	@include clearfix();
	margin-top: 15px;

	.panel-group {
		@include make-sm-column(12);
	}

	.panel {
		border-bottom: 1px solid $MAIN_BORDER_COLOR;
		box-shadow: none;
		&.panel-dolle {
			@include clearfix();
		}
	}

	.panel-heading {
		padding: 0;
	}

	.panel-title {
		text-transform: uppercase;
		@include font-DIN-next-regular(remcalc(20px));
		line-height: 55px;

		a {
			color: $MAIN_COLOR;
			display: block;
			position: relative;
			text-decoration: none !important;
			outline: none !important;
			line-height: 55px !important;
			margin-right: 6px;

			&:after {
				content: $icon-arrow_down;
				@extend .dolle-icon;
				position: absolute;
				right: 0;
				font-size: 9px;
				line-height: 55px;
				color: $SECONDARY_LIGHT_COLOR;
			}
			&:hover {
				color: $SECONDARY_LIGHT_COLOR;
				text-decoration: none;
			}
		}
		a[aria-expanded="true"] {
			&:after {
				content: $icon-arrow_up;
			}
		}
	}


	&.reduce-spacing {

		.panel-title a{
			line-height: 40px !important;
			margin-right: 6px;
			@media(max-width: $screen-sm-max) {
				line-height: 35px !important;
				margin-right: 0px;
			}
			&:after{
				line-height: 40px;
				@media(max-width: $screen-sm-max) {
					line-height: 35px !important;
				}
			}
		}

	}

	.panel-group .panel-heading + .panel-collapse > .panel-body, .panel-group .panel-heading + .panel-collapse > .list-group {
		border-top: 1px solid $MAIN_BORDER_COLOR;
	}

	.panel-body {
		padding: 15px 0;
		.module-text {
			margin: 0 -15px;
		}
	}

}
