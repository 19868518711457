$country-text-color: #fff;
$country-dropdown-icon-size: 36px;
$country-checked-icon-size: 22px;
.choose-country {
    position: relative;
    z-index: 1000;
    font-size: 16px;

    .bg-primary {
        padding: 15px 0;

        > .container {
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;
            @media (min-width: $screen-sm-min) {
                > p {
                    flex-grow: 1;
                }
            }

            @media (max-width: $screen-sm-max) {
                > p {
                    max-width: calc(100% - (#{$country-checked-icon-size} + 10px));
                }
            }
        }
    }

    .icon-x_icon {
        cursor: pointer;
        font-size: 12px;
        padding: 5px;
        color: rgba(255, 255, 255, 0.7);
        @media (min-width: $screen-sm-min) {
            order: 99;
            margin-left: 10px;
        }
    }


    .dropdown-options-list, .dropdown-options-list ul {
        @extend .list-unstyled;
    }

    .dropdown-options-list {
        position: relative;
        margin-right: 15px;
        font-weight: normal;
        border: 1px solid $country-text-color;
        padding: $padding-base-vertical $country-dropdown-icon-size $padding-base-vertical 0;
        line-height: $line-height-base;
        min-width: 280px;
        @media (min-width: $screen-lg-min) {
            margin-left: 15px;
        }

        &:after {
            content: $icon-arrow_right;
            @extend .dolle-icon;
            position: absolute;
            top: 0;
            right: 0;
            width: $country-dropdown-icon-size;
            height: $country-dropdown-icon-size;
            text-align: center;
            line-height: $country-dropdown-icon-size;
            font-size: 10px;
            color: $country-text-color;
        }

        &.has-children {
            cursor: pointer;

            &:after {
                content: $icon-arrow_down;
            }
        }

        li {
            position: relative;
            padding-left: calc(8px + #{$country-checked-icon-size});

            &.checked {
                &:before {
                    content: $icon-check;
                    @extend .dolle-icon;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: calc(8px + #{$country-checked-icon-size});
                    height: $country-checked-icon-size;
                    line-height: $country-checked-icon-size;
                    text-align: center;
                    font-size: 10px;
                }
            }
        }

        ul {
            background-color: $INPUT_BG_COLOR;
            position: absolute;
            top: -7px;
            left: -1px;
            width: calc(100% + 2px);
            min-width: 280px;
            padding: $padding-base-vertical $country-dropdown-icon-size $padding-base-vertical 0;
            border: 1px solid $SECONDARY_LIGHT_COLOR;
            color: $SECONDARY_LIGHT_COLOR;

            li {
                margin-bottom: 5px;

                &.checked {
                    &:before {
                        color: $SECONDARY_LIGHT_COLOR;
                    }
                }
            }

            .spacer {
                width: 20px;
            }

            img, .spacer {
                margin: 0 3px;
                display: inline-block;
            }
        }
    }

    .btn {
        color: $country-text-color;
        border: 1px solid $country-text-color;
        font-size: 16px;
    }

    @media (max-width: $screen-xs-max) {
        .dropdown-options-list, .btn {
            margin-bottom: 8px;
        }
    }

    .country-list {
        background-color: $country-text-color;
        display: none;

        ul {
            @extend .list-unstyled;
            padding-top: 15px;
            padding-bottom: 15px;
            column-count: 2;

            li {
                margin-bottom: 8px;

                span {
                    min-width: 20px;
                    margin-right: 3px;
                }
            }
        }
    }
}

